/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag Fragment */
import { SerializedStyles, jsx } from "@emotion/react";
import { Fragment, useRef } from "react";
import {
  Colors,
  Dropdown,
  Field,
  Grid,
  IconButton,
  LabeledCheckbox,
  LabeledRadio,
  Spacer,
  Text,
  ToggleSwitch,
  Typeahead,
} from "@zapier/design-system";

import { useWorkflowElement } from "./hooks/useWorkflowElement";
import useSWR from "swr";
import { ZAPIER_API_ORIGIN } from "src/utils/env";

function noop(): void {
  // Do nothing; used as an initial value
}

type WorkflowType = "popular" | "specific";

interface WorkflowsToggleProps {
  clientLoaded: boolean;
  containerClassName: SerializedStyles;
  contentWrapperClassName: SerializedStyles;
}

export function WorkflowsToggle({
  clientLoaded,
  containerClassName,
  contentWrapperClassName,
}: WorkflowsToggleProps) {
  // Object with functions used to clear the `<ServiceTypeahead>` elements that
  // are used as multi-selects with button labels beneath them
  const clearRefs = useRef({
    templates: noop,
  });

  const workflowElement = useWorkflowElement();

  const showTemplates =
    workflowElement.elementConfig?.showTemplates ??
    workflowElement.defaultConfig.showTemplates;
  const workflowType =
    workflowElement.elementConfig?.workflowType ??
    workflowElement.defaultConfig.workflowType;
  const templatesQuery =
    workflowElement.elementConfig?.templatesQuery ??
    workflowElement.defaultConfig.templatesQuery;
  const templates =
    workflowElement.elementConfig?.templates ??
    workflowElement.defaultConfig.templates;
  const templateStyle =
    workflowElement.elementConfig?.templateStyle ??
    workflowElement.defaultConfig.templateStyle;
  const templateCallToActionDisplay =
    workflowElement.elementConfig?.templateCallToActionDisplay ??
    workflowElement.defaultConfig.templateCallToActionDisplay;
  const zapCreateFromScratchDisplay =
    workflowElement.elementConfig?.zapCreateFromScratchDisplay ??
    workflowElement.defaultConfig.zapCreateFromScratchDisplay;

  // Fetch the Zapier templates based on the query
  const zapTemplatesSearchResults = useSWR(templatesQuery, async () => {
    const url = new URL("/elements/zap-templates/", ZAPIER_API_ORIGIN);
    url.searchParams.set("ids", templatesQuery);
    const resp = await fetch(url.href);
    const data = await resp.json();
    return data.results;
  });

  function updateWorkflowType(event: React.ChangeEvent<HTMLInputElement>) {
    const type = event.target.value as WorkflowType;
    workflowElement.setGeneratorProperty("workflowType", type);
  }

  return (
    <div css={containerClassName}>
      <div css={contentWrapperClassName}>
        <Text
          color="neutral800"
          fontWeight={600}
          tag="h2"
          type="paragraph1ShortBold"
        >
          Workflows
        </Text>
        <div data-testid="wfe:popularWorkflowsSwitch">
          <ToggleSwitch
            checked={showTemplates}
            checkedText="Show"
            uncheckedText="Hide"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              workflowElement.setGeneratorProperty(
                "showTemplates",
                event.target.checked,
              );
              workflowElement.setGeneratorProperty(
                "appSearchBarDisplay",
                event.target.checked,
              );
            }}
          />
        </div>
      </div>
      {showTemplates && (
        <Fragment>
          <Spacer height={10} />
          <LabeledRadio
            name="workflow-type"
            value="popular"
            checked={workflowType === "popular"}
            // @ts-expect-error Zinnia's types are wrong
            onChange={updateWorkflowType}
          >
            Popular workflows
          </LabeledRadio>
          <LabeledRadio
            name="workflow-type"
            value="specific"
            checked={workflowType === "specific"}
            // @ts-expect-error Zinnia's types are wrong
            onChange={updateWorkflowType}
          >
            Specific workflows
          </LabeledRadio>
          <Spacer height={10} />

          {clientLoaded && (
            <Fragment>
              <Spacer height={20} />
              {workflowType === "popular" && (
                <Field
                  label="Number"
                  renderInput={(inputProps) => (
                    <Dropdown
                      {...inputProps}
                      inputId={inputProps.id}
                      items={Array.from({ length: 10 }, (_, i) => {
                        const n = i + 1;
                        return { label: String(n), value: n };
                      })}
                      onChange={(option) => {
                        workflowElement.setGeneratorProperty(
                          "templatesLimit",
                          option.value,
                        );
                      }}
                      placeholder="5"
                    />
                  )}
                />
              )}
              {workflowType === "specific" && (
                <Fragment>
                  <Field
                    inputId="templates"
                    label="Workflows"
                    isRequired
                    helpTextLineClamp={2}
                    renderHelpText={() => (
                      <Text
                        color="gray60"
                        margin="6px 0 0"
                        tag="div"
                        type="smallPrint3"
                      >
                        Search for the workflows you want to embed by ID
                      </Text>
                    )}
                    renderInput={(props) => {
                      return (
                        <Typeahead
                          getLabelForItem={(item) => item.title}
                          id={props.id}
                          menuAriaLabel="Search workflows by ID"
                          placeholder="Search workflows by ID"
                          items={zapTemplatesSearchResults.data || []}
                          filterItems={(inputValue, items) => {
                            return items.filter((item) => {
                              return (
                                inputValue && item.id.startsWith(inputValue)
                              );
                            });
                          }}
                          onChange={(zt) => {
                            // ZT is set back to null after you hit
                            // Enter because the Typeahead is cleared
                            if (!zt) {
                              return;
                            }
                            workflowElement.setGeneratorProperty("templates", [
                              ...templates,
                              zt,
                            ]);
                            clearRefs.current.templates();
                          }}
                          onInputValueChange={(value) => {
                            workflowElement.setGeneratorProperty(
                              "templatesQuery",
                              value,
                            );
                          }}
                          setClearSelection={(f) => {
                            // Poor typing on Zinnia's part means we
                            // have to cast this here... sigh
                            clearRefs.current.templates = f as any;
                            return f;
                          }}
                        />
                      );
                    }}
                  />
                  <Spacer height={10} />
                  <Grid columns="min-content 1fr" gap="10px">
                    {templates.map((template) => (
                      <Fragment key={template.id}>
                        <IconButton
                          key={template.id}
                          tooltipPosition="north"
                          label="Remove"
                          icon="formX"
                          size="small"
                          onClick={() => {
                            workflowElement.setGeneratorProperty(
                              "templates",
                              templates.filter((zt) => zt.id !== template.id),
                            );
                          }}
                        />
                        <div
                          css={{
                            paddingBottom: 10,
                            borderBottom: `1px solid ${Colors.neutral300}`,
                          }}
                        >
                          {template.title}
                        </div>
                      </Fragment>
                    ))}
                  </Grid>
                  <Spacer height={10} />
                </Fragment>
              )}
              <Spacer height={10} />
              <Field
                label="Display type"
                renderInput={(inputProps) => (
                  <Dropdown
                    {...inputProps}
                    inputId={inputProps.id}
                    items={[
                      { label: "As rows", value: "row" },
                      { label: "As cards", value: "card" },
                    ]}
                    onChange={(setting) =>
                      workflowElement.setGeneratorProperty(
                        "templateStyle",
                        setting.value,
                      )
                    }
                    placeholder="As cards"
                  />
                )}
              />
            </Fragment>
          )}
          <Spacer height={10} />
          {templateStyle != "card" && (
            <div data-testid="toggleSwtich:shouldShowUseThisZap">
              <LabeledCheckbox
                tabIndex={0}
                checked={templateCallToActionDisplay !== false}
                onChange={(event) => {
                  const checkbox = event.target as HTMLInputElement;
                  workflowElement.setGeneratorProperty(
                    "templateCallToActionDisplay",
                    checkbox.checked,
                  );
                }}
              >
                Call to action button
              </LabeledCheckbox>
            </div>
          )}
          <LabeledCheckbox
            tabIndex={0}
            checked={zapCreateFromScratchDisplay ?? false}
            onChange={(event) => {
              const checkbox = event.target as HTMLInputElement;
              workflowElement.setGeneratorProperty(
                "zapCreateFromScratchDisplay",
                checkbox.checked,
              );
            }}
          >
            Footer
          </LabeledCheckbox>
        </Fragment>
      )}
    </div>
  );
}
