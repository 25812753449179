import { assertNever } from "./assertNever";

export interface Filterable {
  slug: string;
  operator: "" | "-";
}

/**
 * Remove duplicate apps, and make opposite operators cancel each other out
 *
 * @example
 * // [foo, bar, -foo, bar] -> [bar, -foo]
 * // [foo, bar, -foo, foo] -> [bar, foo]
 */
export function normalizeFilterable<T extends Filterable>(
  items: T[],
  requiredItemSlug?: T["slug"],
): T[] {
  const map = new Map(items.map((item) => [serializeFilterable(item), item]));
  const set = new Set<string>();
  // Use operator+slug as a serialization and rely on Set insertion ordering and
  // the fact that `.delete` is a noop when the item does not exist to get the
  // right set of items in the right order
  for (const item of items) {
    // We currently require partners to select at least one app, even though
    // technically the ZTE supports having zero apps selected, and just using
    // categories to show your top 5 ZTs.
    if (requiredItemSlug && item.slug === requiredItemSlug) {
      continue;
    }
    switch (item.operator) {
      case "-": {
        set.delete(item.slug);
        set.add("-" + item.slug);
        break;
      }
      case "": {
        set.delete("-" + item.slug);
        set.add(item.slug);
        break;
      }
      default: {
        assertNever(item.operator);
      }
    }
  }
  // Go from set of operator+slug back to full array of objects
  return Array.from(set).flatMap((slug) => {
    const item = map.get(slug);
    if (item) {
      return [item];
    }
    return [];
  });
}

export function serializeFilterable(item: Filterable): string {
  return item.operator + item.slug;
}

export function onlyIncludedFilterables<T extends Filterable>(items: T[]): T[] {
  return items.filter((x) => x.operator === "");
}

export function onlyExcludedFilterables<T extends Filterable>(items: T[]): T[] {
  return items.filter((x) => x.operator === "-");
}
